import React, { useMemo } from 'react';
import block from 'bem-cn';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import './TransactionItem.scss';

let utc = require('dayjs/plugin/utc');

dayjs.extend(utc);
dayjs.extend(customParseFormat);

const b = block('transaction-item');

const TransactionItem = ({ item, needBalance = false }) => {
  const date = useMemo(() => dayjs(item.date, 'YYYY.MM.DD HH:mm')
    .add(1, 'hour')
    .format('YYYY.MM.DD HH:mm'), [item.date]);

  return (
    <div className={b({ needBalance })}>
      <span className={b('column', { align: 'left' })}>{item.email}</span>
      <span className={b('column')}>{date}</span>
      <span className={b('column')}>{item.amount}</span>
      {needBalance && <span className={b('column')}>{item.balance}</span>}
    </div>
  );
};

export default TransactionItem;
