import { actionTypes } from "./actions";
import { initialState } from "./initial";

export function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.ACTION_PROCESSING:
      return {
        ...state,
        actionProcessing: action.payload,
      };
    case actionTypes.GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        actionProcessing: false,
        descendants: {
          transactions: action.payload.descendants.data,
          totalDeposit: action.payload.descendants.totalDeposit,
          totalWithdrawal: action.payload.descendants.totalWithdrawal,
        },
        parents: {
          transactions: action.payload.parents.data,
          totalDeposit: action.payload.parents.totalDeposit,
          totalWithdrawal: action.payload.parents.totalWithdrawal,
        },
      };

    default:
      return { ...state };
  }
}
