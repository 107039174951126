export const initialState = {
  actionProcessing: false,
  descendants: {
    transactions: [],
    totalDeposit: "",
    totalWithdrawal: "",
  },
  parents: {
    transactions: [],
    totalDeposit: "",
    totalWithdrawal: "",
  },
};
