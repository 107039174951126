import React, { useMemo, useState } from "react";
import block from "bem-cn";
import SVGInline from "react-svg-inline";
import { Link } from "react-router-dom";

import itemArrowSVG from "./img/item-arrow.svg";
import headerArrowSVG from "./img/arrow.svg";

import "./MenuItem.scss";

const MenuItem = ({ locale, item, role }) => {
  const b = block("menu-item");
  const [isOpen, changeOpen] = useState(false);
  const itemList = useMemo(() => {
    return item.items.map((temp) => {
      if (temp.roles && !temp.roles.includes(+role)) return null;
      return (
        <Link className={b("element")} key={temp.textId} to={temp.route}>
          {typeof locale[temp.textId] === "object"
            ? locale[temp.textId][role.toString()]
            : locale[temp.textId]}
          <SVGInline svg={itemArrowSVG} className={b("arrow").toString()} />
        </Link>
      );
    });
  }, [b, item, locale, role]);

  return (
    <React.Fragment>
      <div className={b()} onClick={() => changeOpen(!isOpen)}>
        {locale[item.textId]}
        <SVGInline
          svg={headerArrowSVG}
          className={b("image", { open: isOpen }).toString()}
        />
      </div>
      {isOpen && <div className={b("element-list")}>{itemList}</div>}
    </React.Fragment>
  );
};

export default MenuItem;
