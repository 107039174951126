import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  forwardRef,
} from "react";
import block from "bem-cn";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import SVGInline from "react-svg-inline";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";

import Input from "components/Input";
import Spinner from "components/Spinner";

import { actions } from "../redux";

/* import searchSvg from "./img/search.svg"; */
import calendarSvg from "./img/calendar.svg";

import "./Total.scss";

const Total = () => {
  const b = block("total");
  const dispatch = useDispatch();
  const locale = useSelector((state) => state.locale.locale);
  const totalInfo = useSelector((state) => state.total.totalInfo);
  const name = useSelector((state) => state.auth.name, shallowEqual);
  const role = useSelector((state) => state.auth.role, shallowEqual);
  const userList = useSelector((state) => state.total.users, shallowEqual);
  const actionProcessing = useSelector((state) => state.total.actionProcessing);
  const [fromDate, changeFromDate] = useState(
    dayjs(new Date()).add(-3, "day").format("YYYY-MM-DD")
  );
  const [toDate, changeToDate] = useState(
    dayjs(new Date()).format("YYYY-MM-DD")
  );
  useEffect(() => {
    dispatch(actions.getTotal(fromDate, toDate));
  }, []);
  const callBack = useCallback(
    () => dispatch(actions.getTotal(fromDate, toDate)),
    [fromDate, toDate, dispatch]
  );

  const onUserClick = useCallback(
    (id) => {
      if (!actionProcessing) {
        dispatch(actions.getUserData(id, fromDate, toDate));
      }
    },
    [actionProcessing, dispatch, fromDate, toDate]
  );

  const cashier = useMemo(() => {
    return (
      role === 1 && (
        <div key={name} className={b("row", { type: "bold" })}>
          <span className={b("td", { type: "left" })}>{name}</span>
          <span className={b("td")}>{totalInfo.total}</span>
          <span className={b("td")}>{totalInfo.credits}</span>
          <span className={b("td", { type: "right" })}>{totalInfo.debits}</span>
        </div>
      )
    );
  }, [role, totalInfo, name]);

  const users = useMemo(
    () =>
      userList.map((t) => (
        <div
          key={t.userId}
          className={b("row")}
          onClick={() => onUserClick(t.userId)}
        >
          <span className={b("td", { type: "left" })}>{t.username}</span>
          <span className={b("td")}>{t.total}</span>
          <span className={b("td")}>{t.credits}</span>
          <span className={b("td", { type: "right" })}>{t.debits}</span>
        </div>
      )),
    [b, onUserClick, userList]
  );

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className={b("custom-date-input")} onClick={onClick} ref={ref}>
      <span className={b("custom-date-input-value")}>{value}</span>
      <SVGInline className={b("custom-date-input-icon")} svg={calendarSvg} />
    </div>
  ));
  const ExampleCustomInput2 = forwardRef(({ value, onClick }, ref) => (
    <div className={b("custom-date-input")} onClick={onClick} ref={ref}>
      <span className={b("custom-date-input-value")}>{value}</span>
      <SVGInline className={b("custom-date-input-icon")} svg={calendarSvg} />
    </div>
  ));

  return (
    <section className={b()}>
      <Spinner isLoading={actionProcessing} />
      <div className={b("title")}>{locale.login}</div>
      <div className={b("input-block")}>
        <div className={b("label")}>{`${locale.startDate}:`}</div>
        <DatePicker
          dateFormat="yyyy-MM-dd"
          selected={new Date(fromDate)}
          onChange={(date) => changeFromDate(dayjs(date).format("YYYY-MM-DD"))}
          customInput={<ExampleCustomInput />}
        />
        {/* <Input value={fromDate} onChange={e => changeFromDate(e.currentTarget.value)} type="date" /> */}
      </div>
      <div className={b("input-block")}>
        <div className={b("label")}>{`${locale.endingDate}:`}</div>
        <DatePicker
          dateFormat="yyyy-MM-dd"
          selected={new Date(toDate)}
          onChange={(date) => changeToDate(dayjs(date).format("YYYY-MM-DD"))}
          customInput={<ExampleCustomInput2 />}
        />
        {/* <Input value={toDate} onChange={e => changeToDate(e.currentTarget.value)} type="date" /> */}
      </div>
      <div className={b("bottom")}>
        <div
          className={b("button", { isLoading: actionProcessing })}
          onClick={actionProcessing ? (f) => f : callBack}
        >
          {locale.display}
          {/* <SVGInline svg={searchSvg} className={b('ok').toString()} /> */}
        </div>
      </div>
      <article className={b("result")}>
        <div className={b("result-header")}>
          <span className={b("td", { type: "left" })}>{locale.nickname}</span>
          <span className={b("td")}>{locale.total}</span>
          <span className={b("td")}>{locale.deposit}</span>
          <span className={b("td", { type: "right" })}>{locale.withdraw}</span>
        </div>
        {/* <div className={b('row', { type: 'bold' })}>
        <span className={b('td', { type: 'left' })}>{name}</span>
        <span className={b('td')}>{totalInfo.total}</span>
        <span className={b('td')}>{totalInfo.credits}</span>
        <span className={b('td')}>{totalInfo.debits}</span>
      </div> */}
        {cashier}
        {users}
      </article>
    </section>
  );
};

export default Total;
