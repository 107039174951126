export default [
  {
    textId: "cashRegister",
    items: [
      {
        textId: "createUser",
        route: "/user-create",
        roles: [1, 2, 3, 4, 5, 6],
      },
      {
        textId: "balances",
        route: "/users-list",
        roles: [1, 2, 3, 4, 5, 6],
      },
      {
        textId: "cashiersTransactions",
        route: "/total",
        roles: [1, 2, 3, 4, 5, 6],
      },
      {
        textId: "cashierJackpot",
        route: "/cashier-jackpot",
        roles: [1],
      },
    ],
  },
  {
    textId: "partners",
    items: [
      {
        textId: "transactionHistory",
        route: "/transaction-history",
        roles: [1, 2, 3, 4, 5, 6],
      },
    ],
  },
];
