/* eslint-disable no-continue */
import React, { useState, useMemo, useCallback } from "react";
import block from "bem-cn";
import PropTypes from "prop-types";
import SVGInline from "react-svg-inline";
import { shallowEqual, useSelector } from "react-redux";

import ArrowSVG from "./img/arrow.svg";
import ArrowLeftSVG from "./img/arrowLeft.svg";
import ArrowRightSVG from "./img/arrowRight.svg";

import "./Paginator.scss";

const b = block("paginator");

const Paginator = ({ count, currentPage, onPageClick }) => {
  // массив страниц с пропущенной активной страницей
  // const items = useMemo(() => {
  //   let result = [];
  //   for (let i = 0; i < count; i += 1) {
  //     if (currentPage === i) continue;
  //     result.push(i);
  //   }
  //   return result;
  // }, [count, currentPage]);

  // const itemsList = useMemo(
  //   () =>
  //     items.map((item) => (
  //       <li key={item} className={b("item")} onClick={() => onPageClick(item)}>
  //         <span className={b("item-value")}>{item + 1}</span>
  //       </li>
  //     )),
  //   [items, onPageClick]
  // );

  const handleLeftClick = useCallback(() => {
    if (currentPage > 0) onPageClick(currentPage - 1);
  }, [currentPage, onPageClick]);

  const handleRightClick = useCallback(() => {
    if (currentPage < count - 1) onPageClick(currentPage + 1);
  }, [currentPage, count, onPageClick]);

  return (
    <div className={b()}>
      <div className={b("arrow-container")}>
        <SVGInline
          className={b("arrow").toString()}
          svg={ArrowLeftSVG}
          onClick={handleLeftClick}
        />
      </div>
      <div className={b("current")}>{currentPage + 1}</div>
      <div className={b("arrow-container")}>
        <SVGInline
          className={b("arrow").toString()}
          svg={ArrowRightSVG}
          onClick={handleRightClick}
        />
      </div>
    </div>
  );
};

Paginator.propTypes = {
  count: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,

  onPageClick: PropTypes.func.isRequired,
};

export default Paginator;
