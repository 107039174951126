import React, { useState, useCallback, useMemo, forwardRef } from "react";
import block from "bem-cn";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import SVGInline from "react-svg-inline";
import dayjs from "dayjs";

import { actions } from "../../redux";

import "react-datepicker/dist/react-datepicker.css";

import DatePicker from "react-datepicker";

import Input from "components/Input";
import searchSvg from "../img/search.svg";
import calendarSvg from "./img/calendar.svg";

import TransactionItem from "./TransactionItem";

import "./TransactionHistory.scss";

const TransactionHistory = () => {
  const b = block("transaction-history");
  const dispatch = useDispatch();
  const [filterValue, onChangeFilterValue] = useState("");
  const locale = useSelector((state) => state.locale.locale, shallowEqual);
  const {
    descendants,
    parents,
    /* transactions, totalDeposit, totalWithdrawal */
  } = useSelector((state) => state.transaction, shallowEqual);

  const [startDate, setStartDate] = useState(
    dayjs(new Date()).add(-60, "day").$d
  );
  const [endDate, setEndDate] = useState(dayjs(new Date()).$d);

  const changeFilterValue = useCallback((e) => {
    onChangeFilterValue(e.currentTarget.value);
  }, []);

  const onClick = useCallback(() => {
    dispatch(actions.getTransactions({ startDate, endDate }));
  }, [dispatch, endDate, startDate]);

  const list1 = useMemo(
    () =>
      descendants.transactions
        .filter(
          (t) =>
            ~t.email?.toUpperCase().indexOf(filterValue.toUpperCase()) || false
        )
        .map((item, index) => (
          <TransactionItem
            key={`${item.email}_${index}`}
            item={item}
            needBalance
          />
        )) || [],
    [filterValue, descendants.transactions]
  );
  const list2 = useMemo(
    () =>
      parents.transactions
        .filter(
          (t) =>
            ~t.email?.toUpperCase().indexOf(filterValue.toUpperCase()) || false
        )
        .map((item, index) => (
          <TransactionItem key={`${item.email}_${index}`} item={item} />
        )) || [],
    [filterValue, parents.transactions]
  );

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className={b("custom-date-input")} onClick={onClick} ref={ref}>
      <span className={b("custom-date-input-value")}>{value}</span>
      <SVGInline
        className={b("custom-date-input-icon").toString()}
        svg={calendarSvg}
      />
    </div>
  ));
  const ExampleCustomInput2 = forwardRef(({ value, onClick }, ref) => (
    <div className={b("custom-date-input")} onClick={onClick} ref={ref}>
      <span className={b("custom-date-input-value")}>{value}</span>
      <SVGInline
        className={b("custom-date-input-icon").toString()}
        svg={calendarSvg}
      />
    </div>
  ));

  return (
    <div className={b()}>
      <div className={b("filter-block")}>
        <div className={b("filter-item")}>
          <div className={b("filter-title")}>{locale.startDate}</div>
          <div className={b("input")}>
            <DatePicker
              dateFormat="yyyy-MM-dd"
              selected={new Date(startDate)}
              onChange={(date) =>
                setStartDate(dayjs(date).format("YYYY-MM-DD"))
              }
              customInput={<ExampleCustomInput />}
            />
            {/* <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              className={b("date")}
              startDate={startDate}
              dateFormat="dd.MM.yyyy"
              endDate={endDate}
            />
            <SVGInline svg={calendarSvg} className={b("calendar").toString()} /> */}
          </div>
        </div>
        <div className={b("filter-item")}>
          <div className={b("filter-title")}>{locale.endingDate}</div>
          <div className={b("input")}>
            <DatePicker
              dateFormat="yyyy-MM-dd"
              selected={new Date(endDate)}
              onChange={(date) => setEndDate(dayjs(date).format("YYYY-MM-DD"))}
              customInput={<ExampleCustomInput2 />}
            />
            {/* <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              className={b("date")}
              endDate={endDate}
              dateFormat="dd.MM.yyyy"
              minDate={startDate}
            />
            <SVGInline svg={calendarSvg} className={b("calendar").toString()} /> */}
          </div>
        </div>
      </div>
      <div className={b("search-button")} onClick={onClick}>
        <span>{locale.display}</span>
        {/* <SVGInline svg={searchSvg} className={b('search-button-icon').toString()} /> */}
      </div>
      <div className={b("search-block")}>
        <SVGInline svg={searchSvg} className={b("search-icon").toString()} />
        <div className={b("search-input")}>
          <Input
            value={filterValue}
            callBack={changeFilterValue}
            style={{ paddingLeft: "2rem" }}
          />
        </div>
        <div className={b("button")}>{locale.search}</div>
      </div>
      <div className={b("result-wrapper")}>
        <div className={b("list")}>
          <p className={b("list-title")}>{locale.myTransactions}</p>
          <div className={b("users-header1")}>
            <span className={b("user-header-item", { align: "left" })}>
              {locale.email}
            </span>
            <span className={b("user-header-item")}>{locale.date}</span>
            <span className={b("user-header-item")}>{locale.amount}</span>
            <span className={b("user-header-item")}>{locale.balance}</span>
          </div>
          <div className={b("items")}>{list1}</div>
          <div className={b("total")}>
            <p className={b("total-item")}>
              {locale.totalDeposit}
              <span className={b("total-value")}>
                {descendants.totalDeposit}
              </span>
            </p>
            <p className={b("total-item")}>
              {locale.totalWithdrawal}
              <span className={b("total-value")}>
                {descendants.totalWithdrawal}
              </span>
            </p>
          </div>
        </div>
        <div className={b("list")}>
          <p className={b("list-title")}>{locale.transactionsOnMe}</p>
          <div className={b("users-header2")}>
            <span className={b("user-header-item", { align: "left" })}>
              {locale.email}
            </span>
            <span className={b("user-header-item")}>{locale.date}</span>
            <span className={b("user-header-item")}>{locale.amount}</span>
            {/* <span className={b("user-header-item", { type: "right" })}>
              {locale.balance}
            </span> */}
          </div>
          <div className={b("items")}>{list2}</div>
          <div className={b("total")}>
            <p className={b("total-item")}>
              {locale.totalDeposit}
              <span className={b("total-value")}>{parents.totalDeposit}</span>
            </p>
            <p className={b("total-item")}>
              {locale.totalWithdrawal}
              <span className={b("total-value")}>
                {parents.totalWithdrawal}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionHistory;
