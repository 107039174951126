export class TransactionApiConverter {
  convertTransfers = ({
    descendants = {
      data: [],
      total_deposit: "",
      total_withdrawal: "",
    },
    parents = { data: [], total_deposit: "", total_withdrawal: "" },
  }) => {
    return {
      descendants: {
        data: descendants.data,
        totalDeposit: descendants.total_deposit,
        totalWithdrawal: descendants.total_withdrawal,
      },
      parents: {
        data: parents.data,
        totalDeposit: parents.total_deposit,
        totalWithdrawal: parents.total_withdrawal,
      },
    };
  };
}
